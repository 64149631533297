var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-headful',{attrs:{"title":_vm.pageTitle}}),_c('div',{staticStyle:{"overflow":"scroll"},attrs:{"id":"body-content-area-all"}},[_c('div',{staticStyle:{"padding":"25px"},attrs:{"id":"pagelayout"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"has-text-centered heading",style:({ backgroundColor: '#ededed', marginBottom: '0px'})},[_c('h1',{staticClass:"is-size-6",staticStyle:{"padding":"10px 0px"}},[_c('b',[_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))])])]),(_vm.searchResults && !_vm.isLoading)?_c('Grid',{staticClass:"search",style:({ height: '100%'}),attrs:{"filter":_vm.filter,"data-items":_vm.results,"sortable":true,"sort":_vm.sort,"filterable":false,"pageable":Object.assign({}, _vm.pageable,
            {pageSizes: _vm.pageSizes}),"page-size":20,"skip":_vm.skip,"take":_vm.take,"total":_vm.totalRecords,"columns":_vm.staticColumns},on:{"selectionchange":_vm.selectionchange,"headerselectionchange":_vm.headerselectionchange,"filterchange":_vm.filterchange,"sortchange":_vm.sortchange,"pagechange":_vm.pagechange},scopedSlots:_vm._u([{key:"NameCell",fn:function(ref){
          var props = ref.props;
return [_c('td',[_vm._v(" "+_vm._s(props.dataItem.name)+" ")])]}},{key:"TypeCell",fn:function(ref){
          var props = ref.props;
return [_c('td',[_vm._v(" "+_vm._s(props.dataItem.extension.replace('.', '').toUpperCase())+" ")])]}},{key:"SizeCell",fn:function(ref){
          var props = ref.props;
return [_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.getFileSize(props.dataItem.fileSize))+" ")])]}},{key:"UploadDateCell",fn:function(ref){
          var props = ref.props;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(props.dataItem.uploadDate,'timezone', _vm.getTimeZone(), 'MM/DD/YYYY'))+" ")])]}},{key:"UploadedByCell",fn:function(ref){
          var props = ref.props;
return [_c('td',[_vm._v(" "+_vm._s(props.dataItem.uploadUserName)+" ")])]}},{key:"ActionCell",fn:function(ref){
          var props = ref.props;
return [_c('td',{staticStyle:{"width":"100%","padding":"0"}},[_c('div',{staticClass:"action-cell"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.left-start",value:({ content: 'Download ' + (props.dataItem.path.includes('document') ? 'document' : 'report'), container: false, classes: ['tooltip'] }),expression:"{ content: 'Download ' + (props.dataItem.path.includes('document') ? 'document' : 'report'), container: false, classes: ['tooltip'] }",modifiers:{"left-start":true}}]},[_c('a',{attrs:{"href":props.dataItem.path,"download":""}},[_c('i',{staticClass:"fa fa-download"})])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.left-start",value:({ content: 'View '+ (props.dataItem.path.includes('document') ? 'document' : 'report'), container: false, classes: ['tooltip'] }),expression:"{ content: 'View '+ (props.dataItem.path.includes('document') ? 'document' : 'report'), container: false, classes: ['tooltip'] }",modifiers:{"left-start":true}}]},[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.viewReport(props.dataItem)}}},[_c('i',{staticClass:"fa fa-eye"})])])])])]}}],null,false,2058774345)}):_vm._e()],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }