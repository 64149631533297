<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div id="body-content-area-all" style="overflow: scroll">
      <div id="pagelayout" style="padding: 25px;">
        <div class="card">
          <div class="has-text-centered heading" :style="{ backgroundColor: '#ededed', marginBottom: '0px'}">
            <h1 class="is-size-6" style="padding: 10px 0px"><b>{{ pageheading.toLocaleUpperCase() }}</b></h1>
          </div>
          <Grid
            class="search"
            v-if="searchResults && !isLoading"
            :filter="filter"
            :data-items="results"
            :sortable="true"
            :sort="sort"
            :filterable="false"
            :pageable="{
              ...pageable,
              pageSizes: pageSizes
            }"
            :style="{ height: '100%'}"
            :page-size="20"
            :skip="skip"
            :take="take"
            :total="totalRecords"
            :columns="staticColumns"
            @selectionchange="selectionchange"
            @headerselectionchange="headerselectionchange"
            @filterchange="filterchange"
            @sortchange="sortchange"
            @pagechange="pagechange">
              <template v-slot:NameCell="{props}">
                <td>
                  {{props.dataItem.name}}
                </td>
              </template>

              <template v-slot:TypeCell="{props}">
                <td>
                  {{props.dataItem.extension.replace('.', '').toUpperCase()}}
                </td>
              </template>

              <template v-slot:SizeCell="{props}">
                <td style="text-align: right;">
                  {{getFileSize(props.dataItem.fileSize)}}
                </td>
              </template>

              <template v-slot:UploadDateCell="{props}">
                <td>
                  {{ props.dataItem.uploadDate| moment('timezone', getTimeZone(), 'MM/DD/YYYY') }}
                </td>
              </template>

              <template v-slot:UploadedByCell="{props}">
                <td>
                  {{ props.dataItem.uploadUserName }}
                </td>
              </template>

              <template v-slot:ActionCell="{props}">
                <td style="width: 100%; padding: 0;">
                  <div class="action-cell">
                    <div v-tooltip.left-start="{ content: 'Download ' + (props.dataItem.path.includes('document') ? 'document' : 'report'), container: false, classes: ['tooltip'] }">
                      <a :href="props.dataItem.path" download><i class="fa fa-download"></i></a>
                    </div>
                    <div v-tooltip.left-start="{ content: 'View '+ (props.dataItem.path.includes('document') ? 'document' : 'report'), container: false, classes: ['tooltip'] }">
                      <a href="" @click.prevent="viewReport(props.dataItem)"><i class="fa fa-eye"></i></a>
                    </div>
                      <!-- <a v-if="props.dataItem.UploadUserId === clientSession.userId" href="" @click.prevent="deleteReport(props.dataItem)"><i class="fa fa-trash"></i></a> -->
                  </div>
                </td>
              </template>
          </Grid>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'underscore'
import { orderBy, filterBy } from '@progress/kendo-data-query'
import { Grid } from '@progress/kendo-vue-grid'

export default {
  name: 'SearchScreen',
  props: {
    query: String
  },
  components: {
    'Grid': Grid
  },
  data () {
    return {
      pageheading: 'Search Results',
      searchResults: [],
      minResults: 0,
      results: null,
      isLoading: true,
      currentQuery: '',
      props: {
        grid: Grid,
        field: String,
        filterType: String,
        value: [String, Number, Boolean, Date],
        operator: String
      },
      skip: 0,
      take: 20,
      pageSize: 20,
      perPage: 20,
      totalRecords: 0,
      institution: null,
      page: 0,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
      },
      staticColumns: [
        {
          field: 'id',
          title: 'Id',
          filterable: false,
          hidden: true
        },
        {
          field: 'DisplayName',
          title: 'Document Name',
          width: '320px',
          cell: 'NameCell'
        },
        {
          field: 'extension',
          title: 'Type',
          width: '130px',
          cell: 'TypeCell'
        },
        {
          field: 'fileSize',
          title: 'Size',
          cell: 'SizeCell'
        },
        {
          field: 'UploadDate',
          title: 'Uploaded On',
          cell: 'UploadDateCell'
        },
        {
          field: 'UserName',
          title: 'Uploaded By',
          cell: 'UploadedByCell'
        },
        {
          cell: 'ActionCell',
          width: '150px'
        }
      ],
      sort: [
        { field: 'DisplayName', dir: 'desc' }
      ],
      filter: {
        logic: 'and',
        filters: [
        ]
      }
    }
  },
  watch: {
    '$route.query.query': function () {
      this.currentQuery = this.$route.query['query']
      this.getResults()
    }
  },
  computed: {
    ...mapState(['clientSession', 'activeSite']),
    pageSizes () {
      let sizes = [this.minResults]
      let max = this.totalRecords
      let counter = this.minResults
      let min = this.minResults

      if (max !== 0 && min !== 0) {
        while (counter < max) {
          let size = counter + 10
          sizes.push(size > max ? max : size)
          counter += 10
        }
      }

      return sizes
    },
    // https://stackoverflow.com/questions/40382388/how-to-set-url-query-params-in-vue-with-vue-router
    searchQuery () {
      let query = ''

      if (this.query) {
        query = this.query
      } else {
        query = this.$route.query['query']
      }

      return query
    },
    pageTitle () {
      if (this.institution) {
        if (this.totalRecords > 0) {
          return (`(${this.totalRecords})` + ' Search Results - ' + this.institution.name + ' ' + (parseInt(this.activeSite.id) === 1 ? 'BOLI Portal' : 'COLI Portal'))
        }
        return ('Search - ' + this.institution.name + ' ' + (parseInt(this.activeSite.id) === 1 ? 'BOLI Portal' : 'COLI Portal'))
      }

      return 'Search -'
    }
  },
  async mounted () {
    let that = this
    this.$nextTick(() => {
      let elements = [
        '.has-text-centered.heading',
        '.bottom-bar'
      ]

      let func = async (results) => {
        let [numberOfResults, maxHeight] = results
        this.take = numberOfResults
        this.perPage = numberOfResults
        this.minResults = numberOfResults
        this.maxHeight = maxHeight

        await this.getResults()
      }

      that.getListHeights('pagelayout', 31, 100, elements, func.bind(this))

      window.addEventListener('resize', () => {
        that.getListHeights('pagelayout', 31, 100, elements, func.bind(that))
      })
    })
    this.GetInstitution()
  },
  methods: {
    async GetInstitution () {
      let response = await this.axios.get(`/institutions/${this.clientSession.institutionId}/`)

      if (response.status >= 200 && response.status < 300) {
        this.institution = response.data
      }
    },
    selectionchange () {
      // stuff
    },
    headerselectionchange () {
      // stuff
    },
    filterchange () {
      // stuff
    },
    sortchange (e) {
      this.sort = e.sort
      this.getResults()
    },
    pagechange (e) {
      this.take = e.page.take
      this.skip = e.page.skip

      this.page = this.skip / this.take // 5 / 5 = 1 or 15 / 5 = 3

      this.getResults()
    },
    async getReportTypeCodes () {
      let response = await this.axios.get('report-types')
      let records = []

      if (response.status === 200) {
        response.data.records.forEach(function (record) {
          records.push(record.code)
        })

        return records
      }

      return []
    },
    async getResults () {
      this.isLoading = true
      try {
        let response = await this.axios.post('/documents/clients/search', {
          searchTerm: this.searchQuery,
          limit: this.take,
          offset: this.page > 0 ? (this.page) * this.take : 0,
          sort: this.sort[0].field,
          sortDirection: this.sort[0].dir
        })

        if (response.status >= 200 && response.status < 300) {
          this.searchResults = _.sortBy(response.data.records, 'Name').reverse()
          this.totalRecords = response.data.page.totalRecords
        }

        this.searchResults = _.sortBy(this.searchResults, this.sort[0].field).reverse()
        this.results = filterBy(orderBy(this.searchResults, this.sort), this.filter)
      } catch (error) {
        // Do nothing
      }

      this.isLoading = false
    },
    viewReport (document) {
      if (document.path.includes('document')) {
        this.$router.push({
          name: 'ViewDocumentScreen',
          params: {
            id: document.id,
            document: document
          }
        })
      } else {
        this.$router.push({
          name: 'ViewReport',
          params: {
            id: document.id,
            report: document
          }
        })
      }
    }
  }
}
</script>

<style scoped>

  .action-cell {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 25px;
  }
@media (min-width: 800px) {
  tr .action-cell a {
    display: none;
    margin: 0 .25rem;
  }

}

  tr:hover .action-cell a {
    display: inline-block;
  }

  #app-body-area[activelayout="osr-facing"] #body-content-area-all, #app-body-area[activelayout="client-facing"] #body-content-area-all {
    margin: 0!important;
    width: 100%;
    height: calc(100vh - 190px);
  }

  #pagelayout {
    height: 100%;
  }

  .card {
    height: 100%;
  }

  .card .search {
    height: calc(100% - 43px)!important;
  }
</style>

<style>
  .tooltip {
    font-size: 12px!important;
    padding: 5px!important;
  }

  .k-master-row td {
    cursor: default!important;
  }
</style>
